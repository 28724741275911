import { loadable } from 'utils/router';
import { SND_REWARD_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Surprise & Delight',
  subtitle: 'Create/Update Reward',
  authRequired: true,
  component: loadable(() =>
    import(/* webpackChunkName: 'sndreward' */ './components/SnDRewardPage')
  )
};
