import { loadable } from 'utils/router';
import { EMAIL_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Create email campaign',
  authRequired: true,
  component: loadable(() =>
    import(/* webpackChunkName: 'email' */ './components/EmailPage')
  )
};
