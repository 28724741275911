import { loadable } from 'utils/router';
import { POINT_REWARDLIST_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Rewards',
  subtitle: 'Rewards',
  authRequired: true,
  component: loadable(() =>
    import(
      /* webpackChunkName: 'pointrewardlist' */ './components/PointRewardListPage'
    )
  )
};
