import React from 'react';
import PropTypes from 'prop-types';
import './LoadingSpinner.scss';
import { MDBSpinner } from 'mdbreact';

function LoadingSpinner({ size }) {
  return (
    <div className="root">
      {' '}
      <MDBSpinner big />{' '}
    </div>
  );
}

LoadingSpinner.propTypes = {
  size: PropTypes.number
};

export default LoadingSpinner;
