import React from 'react';
import ReactDOM from 'react-dom';
import { version } from '../package.json';
import { init as initErrorHandling } from './utils/errorHandler';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './index.scss';
import App from './App';

// import * as serviceWorker from './serviceWorker'

// Window Variables
// ------------------------------------
window.version = version;

// Initialize Error Handling
// ------------------------------------
initErrorHandling();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
