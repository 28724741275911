import React, { useEffect } from 'react';
import { useAuth } from 'reactfire';
import firebase from 'firebase/app';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import GetBusinesses, { GetBusinesseFeatureMenu } from 'utils/Business';
export const BusinessContext = React.createContext();

const reducer = (state, action) => {
  const registrationDetail = state.registration;
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        currentBusiness: action.currentBusiness,
        allBusiness: action.allBusiness,
        menu:
          action.menu !== null && action.menu !== undefined
            ? action.menu
            : state.menu,
        currentBusinessPaymentDetails:
          action.currentBusinessPaymentDetails != null &&
          action.currentBusinessPaymentDetails !== undefined
            ? action.currentBusinessPaymentDetails
            : state.currentBusinessPaymentDetails,
        currentBusinessInvoiceDetails:
          action.currentBusinessInvoiceDetails !== null &&
          action.currentBusinessInvoiceDetails !== undefined
            ? action.currentBusinessInvoiceDetails
            : state.currentBusinessInvoiceDetails,
        currentLoyaltyProgram:
          action.currentLoyaltyProgram !== null &&
          action.currentLoyaltyProgram !== undefined
            ? action.currentLoyaltyProgram
            : state.currentLoyaltyProgram,
        currentRewards:
          action.currentRewards !== null && action.currentRewards !== undefined
            ? action.currentRewards
            : state.currentRewards,
        currentMembers:
          action.currentMembers !== null && action.currentMembers !== undefined
            ? action.currentMembers
            : state.currentMembers
      };
    case 'LOGOUT':
      console.log('logout');
      window.localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        currentBusiness: null,
        menu: null,
        allBusiness: null,
        currentBusinessPaymentDetails: null,
        currentBusinessInvoiceDetails: null,
        currentLoyaltyProgram: null,
        currentRewards: null,
        currentMembers: null
      };
    case 'REGISTRATION':
      window.localStorage.removeItem('currentBusinessId');
      registrationDetail.data = action.data;
      return {
        ...state,
        registration: registrationDetail
      };
    case 'REGISTRATIONSTEP':
      registrationDetail.progress = action.progress;
      return {
        ...state,
        registration: registrationDetail
      };
    case 'REGISTRATIONSTATUS':
      registrationDetail.status = action.status.status;
      return {
        ...state,
        registration: registrationDetail
      };
    case 'STAFFSMANAGEMENT':
      return {
        ...state,
        staffsDetails: action?.staffsDetails
      };
    case 'UPDATESTAFFDETAILS':
      return {
        ...state,
        staffDetails: action?.staffDetails
      };
    case 'STATEMENTSDETAILS':
      return {
        ...state,
        statementsDetails: action?.statementsDetails
      };
    case 'SETSTATEMENT':
      return {
        ...state,
        statement: action?.statement
      };
    case 'SETSTATEMENTDETAILTRANSACTIONS':
      return {
        ...state,
        statementDetailTransactions: action?.statementDetailTransactions
      };
    default:
      return state;
  }
};

function BusinessContextProvider({ children }) {
  const auth = useAuth();
  const initialState = {
    isAuthenticated: false,
    currentBusiness: null,
    menu: null,
    allBusiness: null,
    currentBusinessPaymentDetails: null,
    currentBusinessInvoiceDetails: null,
    currentLoyaltyProgram: null,
    currentRewards: { SnDRewards: null, PointRewards: null },
    currentMembers: null,
    registration: { data: null, progress: null, status: 'processing' }
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);
  useEffect(() => {
    const fetchBusinessDetails = async () => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const token = (await user.getIdTokenResult()).token;
          const parsedToken = jwtDecode(token);
          if (
            parsedToken.SpecificBusinessRoles !== null &&
            parsedToken.SpecificBusinessRoles !== undefined
          ) {
            const specificRoles =
              parsedToken.SpecificBusinessRoles[
                parsedToken.SpecificBusinessRoles.length - 1
              ];
            let businessId = specificRoles.split('|')[0];
            const businesses = await GetBusinesses(token, businessId);
            if (
              window.localStorage.getItem('currentBusinessId') !==
                'undefined' &&
              window.localStorage.getItem('currentBusinessId') !== null
            ) {
              businessId = window.localStorage.getItem('currentBusinessId');
            }
            const businessMenu = await GetBusinesseFeatureMenu(
              token,
              businessId
            );
            dispatch({
              type: 'LOGIN',
              allBusiness: businesses,
              isAuthenticated: true,
              currentBusiness:
                businesses?.length > 0
                  ? businesses.find(
                      (business) => business.BusinessId === businessId
                    )
                  : null,
              menu: businessMenu
            });
          }
        }
      });
    };
    fetchBusinessDetails();
  }, [auth.currentUser, dispatch]);

  const data = {
    state,
    dispatch
  };

  return (
    <BusinessContext.Provider value={data}>{children}</BusinessContext.Provider>
  );
}

BusinessContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default BusinessContextProvider;
