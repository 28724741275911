import { loadable } from 'utils/router';
import { DASHBOARD_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Dashboard',
  subtitle: '',
  authRequired: true,
  component: loadable(() =>
    import(/* webpackChunkName: 'dashboard' */ './components/DashboardPage')
  )
};
