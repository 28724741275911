import { loadable } from 'utils/router';
import { STAFFS_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Staffs',
  subtitle: '',
  authRequired: true,
  component: loadable(() =>
    import(/* webpackChunkName: 'members' */ './components/StaffsPage')
  )
};
