import { loadable } from 'utils/router';
import { EMAIL_DRAFT_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Draft email',
  authRequired: true,
  component: loadable(() =>
    import(/* webpackChunkName: 'emailDraft' */ './components/EmailDraftPage')
  )
};
