import { loadable } from 'utils/router';
import { QRCODE_PATH as path } from 'constants/paths';

export default {
  path,
  authRequired: false,
  component: loadable(() =>
    import(/* webpackChunkName: 'scan' */ './components/QrCodePage')
  )
};
