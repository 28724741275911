import { loadable } from 'utils/router';
import { MEMBERS_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Members',
  subtitle: '',
  authRequired: true,
  component: loadable(() =>
    import(/* webpackChunkName: 'members' */ './components/MembersPage')
  )
};
