import { loadable } from 'utils/router';
import { SUPPORT_PATH as path } from 'constants/paths';

export default {
  path,
  authRequired: true,
  component: loadable(() =>
    import(/* webpackChunkName: 'support' */ './components/SupportPage')
  )
};
