import { loadable } from 'utils/router';
import { LOGIN_PATH as path } from 'constants/paths';

export default {
  path,
  component: loadable(() =>
    import(/* webpackChunkName: 'Login' */ './components/LoginPage')
  )
};

// import LoginPage from './components/LoginPage';
// export default {
//   path: '/',
//   authRequired: true,
//   component: LoginPage
// };
