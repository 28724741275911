import { AddTrackJsError } from './trackjs.config';
import { UPDATE_BUSINESS_PATH } from 'constants/apipaths';
const apiInt = process.env.REACT_APP_API_BASE_URL;
const fetch = require('node-fetch');

const UpdateBusiness = async ({
  token,
  currentBusiness,
  businessName = null,
  postCode = null,
  suburb = null,
  street = null,
  mobile = null,
  instagramHandle = null,
  logo = null,
  rewardImage = null,
  businessRewardImageGallery = null,
  design = null
}) => {
  let updatedBusiness = null;
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    };

    const updateBusinessRequest = ExtractUpdateBusinessRequest(
      currentBusiness,
      businessName,
      postCode,
      suburb,
      street,
      mobile,
      instagramHandle,
      logo,
      rewardImage,
      businessRewardImageGallery,
      design
    );

    const apiEndPoint = `${apiInt}${UPDATE_BUSINESS_PATH}?businessid=${updateBusinessRequest.BusinessId}`;
    await fetch(apiEndPoint, {
      method: 'PUT',
      body: JSON.stringify(updateBusinessRequest),
      headers: headers
    })
      .then((response) => response.json())
      .then(() => {
        updatedBusiness = ConstructStateBusiness(
          updateBusinessRequest,
          currentBusiness
        );
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  } catch (error) {
    AddTrackJsError('UpdateBusiness-Error- ' + error);
  }
  return updatedBusiness;
};

function ExtractUpdateBusinessRequest(
  currentBusiness,
  businessName,
  postCode,
  suburb,
  street,
  mobile,
  instagramHandle,
  logo,
  rewardImage,
  businessRewardImageGallery,
  design
) {
  const updateBusinessRequest = {
    BusinessId: currentBusiness.BusinessId,
    MerchantId: currentBusiness.LegacyMerchantId
  };
  try {
    if (businessName !== null && businessName !== undefined)
      updateBusinessRequest.Company = businessName;

    if (postCode !== null && postCode !== undefined)
      updateBusinessRequest.PostalCode = postCode;

    if (suburb !== null && suburb !== undefined)
      updateBusinessRequest.Suburb = suburb;

    if (street !== null && street !== undefined)
      updateBusinessRequest.Street = street;

    if (mobile !== null && mobile !== undefined)
      updateBusinessRequest.MobileNumber = mobile;

    if (instagramHandle !== null && instagramHandle !== undefined)
      updateBusinessRequest.InstagramHandle = instagramHandle;

    if (logo !== null && logo !== undefined) updateBusinessRequest.Logo = logo;

    if (rewardImage !== null && rewardImage !== undefined)
      updateBusinessRequest.RewardImage = rewardImage;

    if (
      businessRewardImageGallery !== null &&
      businessRewardImageGallery !== undefined
    )
      updateBusinessRequest.BusinessRewardImageGallery =
        businessRewardImageGallery;

    if (design !== null && design !== undefined)
      updateBusinessRequest.Design = design;
  } catch (error) {
    AddTrackJsError('UpdateBusiness-updateBusinessRequest-Error- ' + error);
  }
  return updateBusinessRequest;
}

function ConstructStateBusiness(request, currentBusiness) {
  try {
    if (
      request.Company !== null &&
      request.Company !== undefined &&
      request.Company !== ''
    )
      currentBusiness.Company = request.Company;

    if (request.PostCode !== null && request.PostCode !== undefined)
      currentBusiness.PostalCode = request.PostCode;

    if (request.Suburb !== null && request.Suburb !== undefined)
      currentBusiness.Suburb = request.Suburb;

    if (request.Street !== null && request.Street !== undefined)
      currentBusiness.Street = request.Street;

    if (request.Mobile !== null && request.Mobile !== undefined)
      currentBusiness.PhoneNumber = request.Mobile;

    if (
      request.InstagramHandle !== null &&
      request.InstagramHandle !== undefined
    )
      currentBusiness.InstagramHandle = request.InstagramHandle;

    if (request.Logo !== null && request.Logo !== undefined)
      currentBusiness.Logo = request.Logo;

    if (request.RewardImage !== null && request.RewardImage !== undefined)
      currentBusiness.RewardImage = request.RewardImage;

    if (
      request.BusinessRewardImageGallery !== null &&
      request.BusinessRewardImageGallery !== undefined
    )
      currentBusiness.BusinessRewardImageGallery = JSON.stringify(
        request.BusinessRewardImageGallery
      );

    if (request.Design !== null && request.Design !== undefined)
      currentBusiness.Design = request.Design;
  } catch (error) {
    AddTrackJsError('UpdateBusiness-ConstructStateBusiness-Error- ' + error);
  }
  return currentBusiness;
}

export default UpdateBusiness;
