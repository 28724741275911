import { loadable } from 'utils/router';
import { SUPPORT_PHONE_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Phone',
  component: loadable(() =>
    import(
      /* webpackChunkName: 'supportPhone' */ './components/SupportPhonePage'
    )
  )
};
