import { loadable } from 'utils/router';
import { PROGRAM_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Program',
  subtitle: 'Update Program',
  authRequired: true,
  component: loadable(() =>
    import(/* webpackChunkName: 'program' */ './components/ProgramPage')
  )
};
