import React from 'react';
import PropTypes from 'prop-types';
import './SubTitle.scss';

function SubTitle({ subTitle }) {
  return (
    <div className="mt-4">
      <span className="text-uppercase sub-title">{subTitle}</span>
    </div>
  );
}

SubTitle.propTypes = {
  subTitle: PropTypes.string
};

export default SubTitle;
