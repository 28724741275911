import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';
import { LOGIN_PATH } from '../../constants/paths';
import SideNav from 'components/SideNav';
import LoadingSpinner from 'components/LoadingSpinner';
import TopNav from 'components/TopNav';
import { MDBContainer } from 'mdbreact';
import useWindowSize from './../../components/WindowSize/WindowSize.hook';
import MainCard from 'components/MainCard';
import './AuthLayout.scss';
const AuthLayout = ({ children, ...rest }) => {
  const [menuState, setMenuState] = useState({
    toggleStateA: false,
    breakWidth: 1200
  });

  const size = useWindowSize();
  function handleToggleClickA(event) {
    return setMenuState({
      ...menuState,
      toggleStateA: !menuState.toggleStateA
    });
  }

  return (
    <MDBContainer className="m-md-auto m-0 lg" fluid>
      <SideNav
        route={rest.location.pathname.replace('/', '')}
        menuState={menuState}
      />

      <TopNav
        title={rest.title}
        toggleMenuState={handleToggleClickA}
        menuState={menuState}
      />

      <main
        className={`${
          size.width > menuState.breakWidth
            ? 'paddingLeft-240p'
            : 'pl-0 paddingTop-5r'
        } pt-4`}>
        <MainCard subtitle={rest.subtitle}>{children}</MainCard>
      </main>
    </MDBContainer>
  );
};

const AuthLayoutRoute = ({ component: Component, path, ...rest }) => {
  const { status, data: signInCheckResult } = useSigninCheck();
  if (status === 'loading') {
    return <LoadingSpinner />;
  }

  if (signInCheckResult.signedIn === true) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <AuthLayout {...rest}>
            <Component {...props} />
          </AuthLayout>
        )}
        user={signInCheckResult.user}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: LOGIN_PATH,
          state: { from: path }
        }}
      />
    );
  }
};

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired
};

AuthLayoutRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  path: PropTypes.string.isRequired
};

export default AuthLayoutRoute;
