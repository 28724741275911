import { loadable } from 'utils/router';
import { ACCOUNT_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'My Profile',
  subtitle: 'Profile',
  authRequired: true,
  component: loadable(() =>
    import(/* webpackChunkName: 'Account' */ './components/AccountPage')
  )
};
