import React, { useEffect, useRef } from 'react';
import { useAuth } from 'reactfire';
import { MDBModal, MDBModalBody, MDBIcon, MDBBtn } from 'mdbreact';
import { LOGIN_PATH, ACCOUNT_PATH, IN_STORE_PATH } from '../../constants/paths';
import { BusinessContext } from '../../context/BusinessContext';
import UploadFile from '../../utils/UploadFile';
import sleep from '../../utils/Sleep';
import UpdateBusiness from '../../utils/UpdateBusiness';
import DispatchDataToBusinessContext from '../../utils/DispatchDataToBusinessContext';
import { GetBusinesseLoyaltyProgram } from '../../utils/BusinessLoyaltyProgram';
import validFileExtensions from '../../data/ValidFileExtensionsForImages.json';
import './ProfileTopNav.scss';
import { AddTrackJsError } from '../../utils/trackjs.config';
import Logo from 'components/Logo/Logo';
import CompressedFile from '../../utils/ImageCompression';
const apiInt = process.env.REACT_APP_API_BASE_URL;
const fetch = require('node-fetch');

const ProfileTopNav = (props) => {
  const [showModel, setShowModel] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  let businessList = [];
  const hiddenFileInputForLogo = React.useRef(null);
  const { state, dispatch } = React.useContext(BusinessContext);

  const handleClickOfLogoUpload = (event) => {
    hiddenFileInputForLogo.current.click();
  };
  const ref = useRef();
  const auth = useAuth();
  useOnClickOutside(ref, () => setShowModel(false));
  const signOut = async () => {
    dispatch({
      type: 'LOGOUT'
    });
    await auth.signOut();
    window.location = LOGIN_PATH;
  };

  // Hook
  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
          document.removeEventListener('mousedown', listener);
          document.removeEventListener('touchstart', listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }

  const manageRewardleAccountClickEvent = async () => {
    window.location = ACCOUNT_PATH;
  };

  if (state.currentBusiness !== null && state.allBusiness !== null) {
    if (state.allBusiness.length === 2) {
      businessList.push(
        state.allBusiness.find(
          (business) => business.BusinessId !== state.currentBusiness.BusinessId
        )
      );
    } else {
      businessList = state.allBusiness.filter(
        (business) => business.BusinessId !== state.currentBusiness.BusinessId
      );
    }
  }

  function CheckFileIsValid(file) {
    const fileExtension = file.name.split('.').pop();
    return (
      fileExtension != null &&
      validFileExtensions.includes('.' + fileExtension.toLowerCase())
    );
  }

  const UpdateLogoFile = async (e) => {
    try {
      setErrorMessage('');
      if (e.target.files.length > 0 && state.currentBusiness !== null) {
        const uploadedFile = e.target.files[0];
        if (CheckFileIsValid(uploadedFile)) {
          const token = (await auth.currentUser.getIdTokenResult()).token;
          console.log(token);
          await UploadFile(
            uploadedFile,
            `businessid=${
              state.currentBusiness.BusinessId
            }&imagetype=public/${'logo'}&imagename=original_${
              uploadedFile.name
            }`
          );

          const compressedImageFile = await CompressedFile(uploadedFile);
          const logoS3Url = await UploadFile(
            compressedImageFile,
            `businessid=${
              state.currentBusiness.BusinessId
            }&imagetype=public/${'logo'}&imagename=${uploadedFile.name}`
          );

          const currentBusiness = state.currentBusiness;
          const updatedBusiness = await UpdateBusiness({
            token,
            currentBusiness,
            logo: logoS3Url
          });
          if (updatedBusiness !== null && updatedBusiness !== undefined) {
            console.log('Updated');
            await DispatchDataToBusinessContext(
              updatedBusiness,
              dispatch,
              state
            );
            await GetBusinesseLoyaltyProgram(
              token,
              state.currentBusiness.BusinessId,
              state,
              dispatch
            );
          }

          sleep(1000).then(async (r) => {
            await CreateMerchantSetting(logoS3Url);
          });
        } else setErrorMessage("Only jpeg's and png are allowed");
      }
    } catch (error) {
      AddTrackJsError('ProfileTopNav-UpdateLogoFile-Error- ' + error);
    }
  };

  async function CreateMerchantSetting(logo) {
    try {
      if (state.currentBusiness !== null) {
        const apiEndPoint = `${apiInt}/settings/v1/merchantsettings`;
        const request = {
          MerchantId: state.currentBusiness.LegacyMerchantId,
          SettingName: 'CONSUMERAPP_LOGO',
          SettingValue: logo
        };
        fetch(apiEndPoint, {
          method: 'PUT',
          body: JSON.stringify(request)
        })
          .then((response) => response.json())
          .then(async (data) => {
            console.log('updated');
          })
          .catch((error) => {
            AddTrackJsError(
              'ProfileTopNav-CreateMerchantSetting-Error- ' + error
            );
            console.error(error);
          });
      }
    } catch (error) {
      AddTrackJsError('ProfileTopNav-CreateMerchantSetting-Error- ' + error);
    }
  }

  const ChooseBusinessId = (businessId) => async () => {
    try {
      await DispatchDataToBusinessContext(
        state.allBusiness.find(
          (business) => business.BusinessId === businessId
        ),
        dispatch,
        state
      );
      window.localStorage.setItem('currentBusinessId', businessId);
      const currentURL = window.location.href;
      if (currentURL.toString().toLowerCase().includes('choosebusiness')) {
        window.location = IN_STORE_PATH;
      } else {
        window.location = currentURL;
      }
    } catch (error) {
      AddTrackJsError('ProfileTopNav-ChooseBusinessId-Error- ' + error);
    }
  };

  return (
    <div className="ml-auto">
      <a onClick={() => setShowModel(!showModel)}>
        <div className="top-nav-logo rounded">
          <Logo logoSize={32} logo={state?.currentBusiness?.Logo}></Logo>
        </div>
      </a>
      {showModel === true && (
        <div ref={ref}>
          <MDBModal
            isOpen={showModel}
            position="top-right"
            backdrop={false}
            onRequestClose={(e) => {
              e.stopPropogation();
              setShowModel(false);
            }}>
            <MDBModalBody>
              <span className="error-message">{errorMessage}</span>
              {/* <div className="dropdown-logo">
                <img
                  className="h-100 w-100 rounded-circle center"
                  style={{
                    objectFit: 'cover'
                  }}
                  src={state.currentBusiness.Logo}
                />
              </div> */}
              <div className="dropdown-logo h-100 w-100 rounded-circle center">
                <Logo logoSize={96} logo={state?.currentBusiness?.Logo}></Logo>
              </div>
              <div
                className="dropdown-logo-upload"
                onClick={handleClickOfLogoUpload}>
                <MDBIcon icon="camera" className="h-100 w-100 rounded-circle" />
                <input
                  type="file"
                  id="img"
                  ref={hiddenFileInputForLogo}
                  name="img"
                  onChange={(evt) => UpdateLogoFile(evt)}
                  accept="image/*"
                  style={{ display: 'none' }}
                />
              </div>
              <h6 className="text-capitalize">
                {state?.currentBusiness?.Company}
              </h6>
              <span className="text-capitalize text-center small d-block">
                {state?.currentBusiness?.Street}{' '}
                {state?.currentBusiness?.Suburb} {}
                {state?.currentBusiness?.PostalCode}
              </span>
              <MDBBtn
                outline
                rounded
                className="text-capitalize pt-2 pb-2"
                onClick={manageRewardleAccountClickEvent}>
                Manage your Rewardle Account
              </MDBBtn>
              <hr></hr>
              {businessList && businessList.length > 0 && (
                <>
                  {businessList.map((business, index) => (
                    <>
                      <a
                        onClick={ChooseBusinessId(business.BusinessId)}
                        key={index}>
                        {business.Company}
                      </a>
                      <br />
                    </>
                  ))}
                  <hr></hr>
                </>
              )}
              <MDBBtn outline size="sm" className="pl-5 pr-5" onClick={signOut}>
                Logout
              </MDBBtn>
              <hr></hr>
              <div className="privacy-section">
                <a className="small">
                  <small>Privacy Policy</small>
                </a>
                <span className="p-2">&#8226;</span>
                <a>
                  <small>Terms of Service</small>
                </a>
              </div>
            </MDBModalBody>
          </MDBModal>
        </div>
      )}
    </div>
  );
};

export default ProfileTopNav;
