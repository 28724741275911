import axios from 'axios';
import { AddTrackJsError } from './trackjs.config';
const apiInt = process.env.REACT_APP_API_BASE_URL;
const fetch = require('node-fetch');

async function UploadFile(file, filePath) {
  try {
    const presignUrl = await GeneratePresignUrl(filePath);

    const headers = {
      'Content-Type': 'image/*'
    };
    await fetch(presignUrl, {
      method: 'PUT',
      // mode: 'cors',
      // cache: 'no-cache',
      // referrerPolicy: 'origin-when-cross-origin',
      body: file,
      headers: headers
    })
      .then(() => {})
      .then(() => {
        console.log('File uploaded successfully!');
      })
      .catch((error) => {
        console.log('file: ' + file + ', filePath:' + filePath);
        console.log('Error:', error);
        AddTrackJsError('UploadFile Fetch-Error- ' + error);
      });

    return presignUrl.split('?')[0];
  } catch (error) {
    AddTrackJsError(
      'UploadFile Data  file: ' + file + ', filePath:' + filePath
    );
    AddTrackJsError('UploadFile -Error- ' + error);
  }
}

async function GeneratePresignUrl(filePath) {
  const s3PresignUrlApiEndpoint = `/loyaltyprogram/s3presignurl?${filePath}`;
  const apiUrl = apiInt + s3PresignUrlApiEndpoint;
  const response = await axios.get(apiUrl, {});
  return response.data;
}

export default UploadFile;
