import React from 'react';
import PropTypes from 'prop-types';
import './TopNav.scss';
import useWindowSize from './../../components/WindowSize/WindowSize.hook';
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBIcon,
  MDBContainer
} from 'mdbreact';
import ProfileTopNav from '../ProfileTopNav/ProfileTopNav';
function TopNav({ toggleMenuState, menuState, title }) {
  const size = useWindowSize();

  return (
    <MDBContainer>
      <MDBNavbar
        dark
        double
        expand="md"
        fixed="top"
        scrolling
        className="navbar bg-primary pt-0 pb-0 align-items-center">
        <MDBNavbarNav
          className={`${
            size.width > menuState.breakWidth ? 'navbar-right-margin' : ''
          } navbar-nav-bar-icon`}
          left>
          <MDBNavItem>
            <div
              onClick={toggleMenuState}
              key="sideNavToggleA"
              className="sideNav-toggle ">
              <MDBIcon icon="bars" color="white" size="1x" />
            </div>
          </MDBNavItem>
        </MDBNavbarNav>
        <MDBNavbarNav className="text-uppercase" left>
          {title}
        </MDBNavbarNav>
        <ProfileTopNav right></ProfileTopNav>
      </MDBNavbar>
    </MDBContainer>
  );
}
TopNav.propTypes = {
  toggleMenuState: PropTypes.func.isRequired,
  menuState: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};
export default TopNav;
