import React from 'react';
import PropTypes from 'prop-types';

export default function Logo(props) {
  const { logoSize = 32, logo, defaultLogo = '' } = props;

  return (
    <div
      style={{
        alignItems: 'center',
        textAlign: 'center'
      }}>
      {logo !== undefined ? (
        <>
          <img
            className="rounded-circle center"
            src={logo}
            style={{
              border: '1px solid var(--blue250)',
              backgroundColor: 'var(--blue250)',
              width: logoSize + 'px',
              height: logoSize + 'px',
              objectFit: 'cover'
            }}
          />
        </>
      ) : (
        <>
          {defaultLogo !== undefined && defaultLogo !== '' ? (
            <>
              <img
                className="rounded-circle center"
                src={defaultLogo}
                style={{
                  width: logoSize + 'px',
                  height: logoSize + 'px',
                  objectFit: 'cover'
                }}
              />
            </>
          ) : (
            <>
              <img
                className="rounded-circle center"
                src={logo}
                style={{
                  border: '1px solid var(--blue250)',
                  backgroundColor: 'var(--blue250)',
                  width: logoSize + 'px',
                  height: logoSize + 'px',
                  objectFit: 'cover'
                }}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}

Logo.propTypes = {
  logoSize: PropTypes.number,
  logo: PropTypes.string,
  defaultLogo: PropTypes.string
};
