import React from 'react';
import PropTypes from 'prop-types';
import './MainCard.scss';
import SubTitle from '../SubTitle/SubTitle';
import { MDBCard, MDBCardBody } from 'mdbreact';
const MainCard = ({ children, subtitle }) => {
  return (
    <div className="m-auto maincard">
      <SubTitle subTitle={subtitle}></SubTitle>
      <MDBCard className="cardshadow">
        <MDBCardBody>{children}</MDBCardBody>
      </MDBCard>{' '}
    </div>
  );
};

MainCard.propTypes = {
  subtitle: PropTypes.string,
  children: PropTypes.element.isRequired
};

export default MainCard;
