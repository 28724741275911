import { loadable } from 'utils/router';
import { STAFF_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Staff',
  subtitle: 'Create/Update Staff',
  authRequired: true,
  component: loadable(() =>
    import(/* webpackChunkName: 'members' */ './components/StaffPage')
  )
};
