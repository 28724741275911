import { AddTrackJsError } from './trackjs.config';
const apiInt = process.env.REACT_APP_API_BASE_URL;
const axios = require('axios');

export default async function DispatchDataToBusinessContext(
  currentBusiness,
  dispatch,
  state
) {
  const currentBusinessIndex = state.allBusiness.findIndex(
    (item) => item.BusinessId === currentBusiness.BusinessId
  );
  const allBusinessCopy = state.allBusiness;
  allBusinessCopy[currentBusinessIndex] = currentBusiness;
  await dispatch({
    type: 'LOGIN',
    currentBusiness: currentBusiness,
    menu: state.menu,
    allBusiness: allBusinessCopy,
    isAuthenticated: true,
    currentBusinessPaymentDetails: state.currentBusinessPaymentDetails
  });
}

export async function DispatchPaymentDetailsToBusinessContext(
  state,
  dispatch,
  currentBusinessPaymentDetails
) {
  if (currentBusinessPaymentDetails?.length > 0)
    currentBusinessPaymentDetails[0].BusinessId =
      state.currentBusiness.BusinessId;
  await dispatch({
    type: 'LOGIN',
    currentBusiness: state.currentBusiness,
    allBusiness: state.allBusiness,
    isAuthenticated: true,
    currentBusinessPaymentDetails: currentBusinessPaymentDetails
  });
}

export async function DispatchInvoiceDetailsToBusinessContext(
  currentBusiness,
  dispatch,
  state,
  currentBusinessInvoiceDetails
) {
  if (currentBusinessInvoiceDetails?.length > 0)
    currentBusinessInvoiceDetails[0].BusinessId = currentBusiness.BusinessId;
  await dispatch({
    type: 'LOGIN',
    currentBusiness: currentBusiness,
    allBusiness: state.allBusiness,
    isAuthenticated: true,
    currentBusinessInvoiceDetails: currentBusinessInvoiceDetails
  });
}

export async function DispatchLoyaltyProgramToBusinessContext(
  dispatch,
  state,
  currentLoyaltyProgram
) {
  await dispatch({
    type: 'LOGIN',
    currentBusiness: state.currentBusiness,
    allBusiness: state.allBusiness,
    isAuthenticated: true,
    currentLoyaltyProgram: currentLoyaltyProgram
  });
}

export async function DispatchCurrentSnDRewardsToBusinessContext(
  dispatch,
  state,
  currentSnDRewards
) {
  let allRewards = state.currentRewards;
  if (state.currentRewards === undefined || state.currentRewards === null)
    allRewards = {};
  allRewards.SnDRewards = currentSnDRewards;
  await dispatch({
    type: 'LOGIN',
    currentBusiness: state.currentBusiness,
    allBusiness: state.allBusiness,
    isAuthenticated: true,
    currentRewards: allRewards
  });
}

export async function DispatchCurrentPointRewardsToBusinessContext(
  dispatch,
  state,
  currentPointRewards
) {
  let allRewards = state.currentRewards;
  if (state.currentRewards === undefined || state.currentRewards === null)
    allRewards = {};
  allRewards.PointRewards = currentPointRewards;
  await dispatch({
    type: 'LOGIN',
    currentBusiness: state.currentBusiness,
    allBusiness: state.allBusiness,
    isAuthenticated: true,
    currentRewards: allRewards
  });
}

export async function DispatchRegistrationDataToBusinessContext(
  currentRegistrationData,
  dispatch
) {
  await dispatch({
    type: 'REGISTRATION',
    data: currentRegistrationData
  });
  try {
    const apiEndPoint = `${apiInt}/loyaltyprogram/rewardlebasic/register/incomplete?businessid=${currentRegistrationData.BusinessId}`;

    const request = {
      BusinessId: currentRegistrationData.BusinessId,
      Data: currentRegistrationData
    };
    if (currentRegistrationData.CreationDate !== undefined)
      request.CreationDate = currentRegistrationData.CreationDate;
    if (
      currentRegistrationData.Step !== undefined &&
      currentRegistrationData.Step !== null
    )
      request.Step = currentRegistrationData.Step;

    const headers = {
      'Content-Type': 'application/json'
    };
    console.log(apiEndPoint);
    console.log(headers);
    const response = await axios.post(apiEndPoint, request, { headers });
    if (response.status !== 200) {
      AddTrackJsError(
        `DispatchRegistrationDataToBusinessContext-Error API Response: ${JSON.stringify(
          response
        )}`
      );
    }
    return response;
  } catch (error) {
    console.error(error);
    AddTrackJsError(
      `DispatchRegistrationDataToBusinessContext-Error : ${error}`
    );
    return null;
  }
}

export async function DispatchRegistrationProgressToBusinessContext(
  currentRegistrationProgress,
  dispatch
) {
  await dispatch({
    type: 'REGISTRATIONSTEP',
    progress: currentRegistrationProgress
  });
}

export async function DispatchRegistrationStatusToBusinessContext(
  currentRegistrationStatus,
  dispatch
) {
  await dispatch({
    type: 'REGISTRATIONSTATUS',
    status: currentRegistrationStatus
  });
}

export async function DispatchCurrentMembersToBusinessContext(
  dispatch,
  state,
  currentMembers
) {
  await dispatch({
    type: 'LOGIN',
    currentBusiness: state.currentBusiness,
    allBusiness: state.allBusiness,
    isAuthenticated: true,
    currentMembers: currentMembers
  });
}

export async function DispatchSetStaffsDetails(dispatch, staffsDetails) {
  await dispatch({
    type: 'STAFFSMANAGEMENT',
    staffsDetails: staffsDetails
  });
}

export async function UpdateStaffDetails(dispatch, staffDetails) {
  await dispatch({
    type: 'UPDATESTAFFDETAILS',
    staffDetails: staffDetails
  });
}

export async function DispatchSetStatementsDetails(
  dispatch,
  statementsDetails
) {
  await dispatch({
    type: 'STATEMENTSDETAILS',
    statementsDetails: statementsDetails
  });
}

export async function setStatement(dispatch, statement) {
  await dispatch({
    type: 'SETSTATEMENT',
    statement: statement
  });
}

export async function setStatementsDetailTransaction(
  dispatch,
  statementDetailTransactions
) {
  await dispatch({
    type: 'SETSTATEMENTDETAILTRANSACTIONS',
    statementDetailTransactions: statementDetailTransactions
  });
}
