import { loadable } from 'utils/router';
import { EMAIL_PREVIOUS_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Previous Campaiagn',
  authRequired: true,
  component: loadable(() =>
    import(
      /* webpackChunkName: 'emailPrevious' */ './components/EmailPreviousPage'
    )
  )
};
