import { loadable } from 'utils/router';
import { SND_REWARDLIST_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Surprise & Delight',
  subtitle: 'Rewards',
  authRequired: true,
  component: loadable(() =>
    import(
      /* webpackChunkName: 'sndrewardlist' */ './components/SnDRewardListPage'
    )
  )
};
