import { loadable } from 'utils/router';
import { POINT_REWARD_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Reward',
  subtitle: 'Create/Update Reward',
  authRequired: true,
  component: loadable(() =>
    import(/* webpackChunkName: 'reward' */ './components/PointRewardPage')
  )
};
