import { loadable } from 'utils/router';
import { CHOOSE_BUSINESS_PATH as path } from 'constants/paths';
export default {
  path,
  authRequired: true,
  title: 'Choose the business',
  component: loadable(() =>
    import(/* webpackChunkName: 'Login' */ './components/ChooseBusiness')
  )
};
