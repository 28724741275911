import { loadable } from 'utils/router';
import { SUPPORT_EMAIL_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Email',
  component: loadable(() =>
    import(
      /* webpackChunkName: 'supportEmail' */ './components/SupportEmailPage'
    )
  )
};
