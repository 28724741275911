import { loadable } from 'utils/router';
import { USER_MANAGEMENT_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'usermgmt',
  subtitle: '',
  authRequired: false,
  component: loadable(() =>
    import(/* webpackChunkName: 'members' */ './components/UserManagementPage')
  )
};
