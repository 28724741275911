import { loadable } from 'utils/router';
import { IN_STORE_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Poster',
  authRequired: true,
  component: loadable(() =>
    import(/* webpackChunkName: 'inStore' */ './components/InStorePage')
  )
};
