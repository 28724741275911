import { AddTrackJsError } from './trackjs.config';
import { BUSINESS_LOYALTYPROGRAM_PATH } from 'constants/apipaths';
import { DispatchLoyaltyProgramToBusinessContext } from './DispatchDataToBusinessContext';
const axios = require('axios');
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export async function GetBusinesseLoyaltyProgram(
  token,
  businessId,
  state,
  dispatch
) {
  let businessLoyaltyProgram = {};
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    };
    console.log(businessId);
    const apiEndpoint = `${apiBaseUrl}${BUSINESS_LOYALTYPROGRAM_PATH}?businessid=${businessId}`;
    const result = await axios.get(apiEndpoint, { headers });
    if (result.data !== undefined && result.data !== null) {
      businessLoyaltyProgram = result.data[0];
    }
    await DispatchLoyaltyProgramToBusinessContext(
      dispatch,
      state,
      businessLoyaltyProgram
    );
    return businessLoyaltyProgram;
  } catch (error) {
    console.error(error);
    AddTrackJsError(`GetBusinesseLoyaltyProgram-Error : ${error}`);
    return null;
  }
}
