import React from 'react';
import { Switch } from 'react-router-dom';
// import LoadingSpinner from '../components/LoadingSpinner';
import { PrivateRoute } from '../utils/router';
import AuthLayoutRoute from '../layouts/AuthLayout/AuthLayout';
import UnAuthLayoutRoute from '../layouts/UnAuthLayout/UnAuthLayout';
// import Home from './Home';
import LoginRoute from './Login';
import VerifyLoginRoute from './VerifyLogin';
import AccountRoute from './Account';
import NotFoundRoute from './NotFound';
import InStore from './InStore';
import ChooseBusiness from './ChooseBusiness';
import Program from './Program';
import Email from './Email';
import Support from './Support';
import Register from './Register';
import Branding from './Branding';
import EmailScheduled from './EmailScheduled';
import EmailDraft from './EmailDraft';
import CardImage from './CardImage';
import Dashboard from './Dashboard';
import EmailPrevious from './EmailPrevious';
import PaymentDetails from './PaymentDetails';
import Invoices from './Invoices';
import SupportEmail from './SupportEmail';
import SupportPhone from './SupportPhone';
import QrCode from './QrCode';
import SnDRewardList from './SnDRewardList';
import SnDReward from './SnDReward';
import registerv3 from './RegisterV3';
import PointRewardList from './PointRewardList';
import PointReward from './PointReward';
import Members from './Members';
import Invoice from './Invoice';
import UserManagement from './UserManagement';
import loginv2 from './LoginV2';
import ForgotPassword from './ForgotPassword';
import Statements from './Statement/Statements';
import StatementDetail from './Statement/StatementDetail';
import Staffs from './StaffManagement/Staffs';
import Staff from './StaffManagement/Staff';

export default function createRoutes() {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={LoginRoute.component} />
      {
        /* Build Route components from routeSettings */
        [
          InStore,
          Program,
          Email,
          AccountRoute,
          Support,
          Register,
          Branding,
          CardImage,
          EmailScheduled,
          EmailDraft,
          Dashboard,
          LoginRoute,
          VerifyLoginRoute,
          EmailPrevious,
          SupportEmail,
          SupportPhone,
          ChooseBusiness,
          PaymentDetails,
          Invoices,
          QrCode,
          SnDRewardList,
          SnDReward,
          registerv3,
          PointRewardList,
          PointReward,
          Members,
          Invoice,
          UserManagement,
          loginv2,
          ForgotPassword,
          Statements,
          StatementDetail,
          Staffs,
          Staff
          /* Add More Routes Here */
        ].map((settings) =>
          settings.authRequired ? (
            <AuthLayoutRoute key={`Route-${settings.path}`} {...settings} />
          ) : (
            <UnAuthLayoutRoute key={`Route-${settings.path}`} {...settings} />
          )
        )
      }
      <UnAuthLayoutRoute component={NotFoundRoute.component} />
    </Switch>
  );
}
