import { MDBContainer } from 'mdbreact';
import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import './UnAuthLayout.scss';

const UnAuthLayout = ({ children, ...rest }) => {
  return <MDBContainer className="m-auto m-0 p-0">{children}</MDBContainer>;
};

const UnAuthLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <UnAuthLayout>
          <Component {...props} />
        </UnAuthLayout>
      )}
    />
  );
};

UnAuthLayout.propTypes = {
  children: PropTypes.element.isRequired
};

UnAuthLayoutRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
};

export default UnAuthLayoutRoute;
