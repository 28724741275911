import React from 'react';
import { FirebaseAppProvider } from 'reactfire';
import { BrowserRouter as Router } from 'react-router-dom';
import BusinessContextProvider from './context/BusinessContext';
import ThemeProvider from 'modules/theme/ThemeProvider';
import createRoutes from './routes';
// eslint-disable-next-line no-unused-vars
import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  appId: process.env.REACT_APP_FIREBASE_appId
};

// Enable Real Time Database emulator if environment variable is set
if (process.env.REACT_APP_FIREBASE_DATABASE_EMULATOR_HOST) {
  firebaseConfig.databaseURL = `http://${process.env.REACT_APP_FIREBASE_DATABASE_EMULATOR_HOST}?ns=${firebaseConfig.projectId}`;
  console.debug(`RTDB emulator enabled: ${firebaseConfig.databaseURL}`); // eslint-disable-line no-console
}

function App() {
  const routes = createRoutes();
  return (
    <ThemeProvider>
      <FirebaseAppProvider firebaseConfig={firebaseConfig} initPerformance>
        <BusinessContextProvider>
          <Router>{routes}</Router>
        </BusinessContextProvider>
      </FirebaseAppProvider>
    </ThemeProvider>
  );
}

export default App;
