import { loadable } from 'utils/router';
import { CARD_IMAGE_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Image for card Design',
  authRequired: true,
  component: loadable(() =>
    import(/* webpackChunkName: 'cardImage' */ './components/CardImagePage')
  )
};
