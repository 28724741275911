export const LOYALTY_PROGRAM_BY_QRCODES_PATH =
  '/loyaltyprogram/loyaltyprogram/qrcodes';
export const USERS_ASSCOAITED_TO_BUSINESS_PATH =
  '/users/rewardlebasic/businessmembers';
export const BUSINESS_TOTAL_CHECKINS_AND_POINTS_PATH =
  '/loyaltyprogram/rewardlebasic/all/points';
export const BUSINESS_CHECKINS_AND_POINTS_PATH =
  '/loyaltyprogram/rewardlebasic/points';
export const BUSINESS_REWARD_ISSUED_PATH =
  '/loyaltyprogram/rewardlebasic/rewards';
export const BUSINESS_REDEEMED_REWARD_PATH =
  '/loyaltyprogram/rewardlebasic/rewardsredeemed';
export const BUSINESS_SOCIAL_AMPLIFIER_PATH =
  '/users/rewardlebasic/businesssocialamplifier';
export const IS_REWARDLE_BASIC_BUSINESS_PATH = '/users/rewardlebasic/email';
export const UPDATE_BUSINESS_PATH = '/datacollection/v1/business/businessauth';
export const BUSINESS_LOYALTYPROGRAM_PATH =
  '/loyaltyprogram/loyaltyprograms/business';
export const UPDATE_BUSINESS_LOYALTYPROGRAM_PATH =
  '/loyaltyprogram/loyaltyprogram/business';
export const BUSINESS_REWARDLIST_PATH = '/loyaltyprogram/businessreward';
export const BUSINESS_REWARD_PATH = '/loyaltyprogram/businessreward/business';
export const BUSINESS_FEATURE_MENU_PATH =
  '/billingplatformnode/menu/businessadmin';
export const GET_BUSINESS_MEMBERS_PATH = '/businessmembers/users';
export const GET_REWARDLE_PAYMENT_REPORT_PATH =
  '/rewardlepay/business/transactions/report';
export const GET_FEATURES_FOR_BUSINESS_PATH =
  '/billingplatformnode/business/featuremaster';
