import { loadable } from 'utils/router';
import { BRANDING_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Logos & Pictures',
  subtitle: 'Update Reward Card Design',
  authRequired: true,
  component: loadable(() =>
    import(/* webpackChunkName: 'branding' */ './components/BrandingPage')
  )
};
