import { loadable } from 'utils/router';
import { PAYMENT_DETAILE_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'My Profile',
  subtitle: 'Payment Details',
  authRequired: true,
  component: loadable(() =>
    import(/* webpackChunkName: 'Account' */ './components/PaymentDetailsPage')
  )
};
