import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ThemeSettings from '../../theme';
import ThemeContext from './ThemeContext';

export default function ThemeProvider({ children }) {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [isDarkMode, changeIsDarkMode] = useState(false);

  const theme = React.useMemo(() => ({ ...ThemeSettings }[isDarkMode]));

  const contextValue = {
    isDarkMode,
    toggleDarkMode: useCallback(() => changeIsDarkMode(!isDarkMode), [
      changeIsDarkMode,
      isDarkMode
    ])
  };

  return (
    <ThemeContext.Provider theme={theme} value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.element
};
