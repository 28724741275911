import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './SideNav.scss';
import rewardlelogo from '../../static/rewardle_ic-logo.svg';
import { BusinessContext } from 'context/BusinessContext';

import {
  MDBSideNavNav,
  MDBSideNav,
  MDBSideNavLink,
  MDBSideNavCat
} from 'mdbreact';

function SideNav({ menuState, route }) {
  // const [showEmailCampaign, setshowEmailCampaign] = useState('emailCampaignsid');
  // const [showAccount, setshowAccount] = useState('accountid');
  // const [showCardDesign, setshowCardDesign] = useState('cardDesignid');
  // const [showSubMenu, setShowSubMenu] = useState(false);
  const { state } = React.useContext(BusinessContext);
  const [menu, setMenu] = useState();
  // const [showActiveSubMenu, setShowActiveSubMenu] = useState(false);

  let activeSubMenu = [];
  useEffect(() => {
    setMenu(state.menu);
    if (
      route === 'email' ||
      route === 'emailschedule' ||
      route === 'emaildraft' ||
      route === 'emailprevious'
    ) {
      // setshowEmailCampaign('emailCampaigns');
    } else if (route === 'branding' || route === 'cardimage') {
      // setshowCardDesign('cardDesign');
    } else if (
      route === 'account' ||
      route === 'paymentdetails' ||
      route === 'invoices'
    ) {
      // setshowAccount('account');
    } else {
      // setshowCardDesign('cardDesignid');
      // setshowEmailCampaign('emailCampaignsid');
      // setshowAccount('accountid');
    }
  }, [route, menuState, state.menu]);

  return (
    <MDBSideNav
      href="/"
      logo={rewardlelogo}
      triggerOpening={menuState.toggleStateA}
      breakWidth={menuState.breakWidth}
      mask="strong"
      fixed>
      <MDBSideNavNav className="text-uppercase mt-0">
        {/* <MDBSideNavLink className="businessname">
          {window.localStorage.getItem('businessname')}
        </MDBSideNavLink> */}
        {menu &&
          menu.map((m, index) =>
            m.SubMenu &&
            m.SubMenu.length > 0 &&
            (activeSubMenu = m.SubMenu.map((x) => x.Link.replace('/', ''))) ? (
              <MDBSideNavCat
                href={m.Link}
                name={m.Name}
                key={`menu_submenu_${m.Name}_${index}`}
                tag="li">
                {m.SubMenu.map((s, sindex) => (
                  <MDBSideNavLink
                    key={`submenu_${s.Name}_${sindex}`}
                    exact
                    to={s.Link}
                    className={
                      activeSubMenu.includes(route)
                        ? 'side-nav-submenu-show'
                        : 'side-nav-submenu-hide'
                    }>
                    {s.Name}
                  </MDBSideNavLink>
                ))}
              </MDBSideNavCat>
            ) : (
              <MDBSideNavLink key={`menu_${m.Name}_${index}`} exact to={m.Link}>
                {m.Name}
              </MDBSideNavLink>
            )
          )}
      </MDBSideNavNav>
    </MDBSideNav>
  );
}

SideNav.propTypes = {
  menuState: PropTypes.object.isRequired,
  route: PropTypes.string.isRequired
};
export default SideNav;
