import { TrackJS } from 'trackjs';
TrackJS.install({
  token: process.env.REACT_APP_TRACKJS_TOKEN,
  application: process.env.REACT_APP_TRACKJS_APPLICATION,
  onError: function (payload) {
    if (
      payload !== undefined &&
      ((payload.network !== undefined &&
        payload.network.length > 0 &&
        payload.network.some(
          (x) =>
            x.url.includes(
              'https://www.googleapis.com/identitytoolkit/v3/relyingparty/emailLinkSignin?key='
            ) ||
            x.url.includes(
              'https://www.googleapis.com/identitytoolkit/v3/relyingparty/getOobConfirmationCode?key='
            ) ||
            x.url.includes(
              'https://firebaselogging-pa.googleapis.com/v1/firelog/legacy/log?key='
            ) ||
            x.url.includes(
              'https://www.googleapis.com/identitytoolkit/v3/relyingparty/getAccountInfo?key='
            ) ||
            x.url.includes(
              'https://www.googleapis.com/identitytoolkit/v3/relyingparty/resetPassword?key='
            )
        ) === true) ||
        (payload.message !== undefined &&
          payload.message.startsWith('["Warning:') === true))
    ) {
      return false;
    } else {
      return true;
    }
  }
});

export function AddTrackJsError(error) {
  if (!window.TrackJS) {
    return;
  } // Safety Check

  TrackJS.track(error);
}
