export const ACCOUNT_PATH = '/account';
export const LOGIN_PATH = '/login';
export const VERIFY_LOGIN_PATH = '/verifylogin';
export const CHOOSE_BUSINESS_PATH = '/choosebusiness';
export const SIGNUP_PATH = '/signup';
export const EMAIL_PATH = '/email';
export const PROGRAM_PATH = '/program';
export const IN_STORE_PATH = '/instore';
export const SUPPORT_PATH = '/support';
export const REGISTER_PATH = '/register';
export const BRANDING_PATH = '/branding';
export const CARD_IMAGE_PATH = '/cardimage';
export const DASHBOARD_PATH = '/dashboard';
export const EMAIL_DRAFT_PATH = '/emaildraft';
export const EMAIL_SCHEDULED_PATH = '/emailschedule';
export const EMAIL_PREVIOUS_PATH = '/emailprevious';
export const PAYMENT_DETAILE_PATH = '/paymentdetails';
export const INVOICES_DETAILE_PATH = '/invoices';
export const SUPPORT_EMAIL_PATH = '/supportemail';
export const SUPPORT_PHONE_PATH = '/supportphone';
export const QRCODE_PATH = '/qrcode';
export const MINIPOSTER_PATH = '/miniposter';
export const SND_REWARDLIST_PATH = '/sndrewards';
export const SND_REWARD_PATH = '/sndreward';
export const REGISTER_V_3_PATH = '/registerv3';
export const POINT_REWARDLIST_PATH = '/rewards';
export const POINT_REWARD_PATH = '/reward';
export const MEMBERS_PATH = '/members';
export const INVOICE_PATH = '/invoice';
export const USER_MANAGEMENT_PATH = '/usermgmt';
export const SEND_SIGN_IN_LINK = '/users/sendsigninlinkemail';
export const OLD_LOGIN_PATH = '/old-login';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const STAFFS_PATH = '/staffs';
export const STAFF_PATH = '/staff';
export const STATEMENTS_PATH = '/statements';
export const STATEMENT_DETAIL_PATH = '/statement';
