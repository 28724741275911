import { loadable } from 'utils/router';
import { EMAIL_SCHEDULED_PATH as path } from 'constants/paths';

export default {
  path,
  title: 'Scheduled Campaiagn',
  authRequired: true,
  component: loadable(() =>
    import(
      /* webpackChunkName: 'emailScheduled' */ './components/EmailScheduledPage'
    )
  )
};
