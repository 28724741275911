import { AddTrackJsError } from './trackjs.config';
import { BUSINESS_FEATURE_MENU_PATH } from 'constants/apipaths';
const axios = require('axios');
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export default async function GetBusinesses(token, businessId) {
  let businessInfos = [];
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    };
    console.log(businessId);
    const apiEndpoint = `${apiBaseUrl}/datacollectionbusiness/rewardlebasic/businesses?businessid=${businessId}`;
    const result = await axios.get(apiEndpoint, { headers });
    console.log(result.data);
    businessInfos = result.data;
    return businessInfos;
  } catch (error) {
    console.error(error);
    AddTrackJsError(`GetBusinesses-Error : ${error}`);
    return null;
  }
}

export async function GetBusinesseFeatureMenu(token, businessId) {
  let businessFeatureMenu = [];
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    };
    console.log(businessId);
    const apiEndpoint = `${apiBaseUrl}${BUSINESS_FEATURE_MENU_PATH}?businessid=${businessId}`;
    const result = await axios.get(apiEndpoint, { headers });
    if (result.data !== undefined && result.data.Menu !== undefined) {
      businessFeatureMenu = result.data.Menu;
    }
    return businessFeatureMenu;
  } catch (error) {
    console.error(error);
    AddTrackJsError(`GetBusinesseFeatureMenu-Error : ${error}`);
    return null;
  }
}
